import React, { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import { Grid } from "grommet";
import { Link } from "gatsby";

import Text from "@components/Text";
import Box from "@components/Box";
import Svg from "@components/Svg";
import ResponsiveContainerBox from "@components/ResponsiveContainerBox";
import Paragraph from "@components/Paragraph";
import Button from "@components/Button";
import useOnClickOutside from "@hooks/useOnClickOutside";
import { getDesktopBreakpoint } from "@utils/Grommet";
import { DownArrowIcon } from "@icons/Common";

import SubNavArrow from "../SubNavArrow";

const SubNavBox = styled(Box)<{ open?: boolean }>`
  bottom: 0;
  transform: translateY(${({ open }) => (open ? 100 : 0)}%);
  transition: transform ease-in-out 300ms;
`;

const SubNavContainerBox = styled(ResponsiveContainerBox)`
  @media (max-width: ${getDesktopBreakpoint}px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const SubNavItemBox = styled(Box)`
  width: 100%;
  max-width: 255px;
`;

const StoriesGrid = styled(Grid)`
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 36px;

  @media (max-width: ${getDesktopBreakpoint}px) {
    grid-gap: 24px;
  }
`;

const MenuLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export type TProps = {
  solutions?: ReadonlyArray<Queries.SolutionsNavFragment> | null;
  customerStrories?: ReadonlyArray<Queries.CustomerStoriesNavFragment> | null;
};

const DesktopNav = ({ solutions, customerStrories }: TProps) => {
  const containerRef = useRef(null);
  const [solutionsOpen, setSolutionsOpen] = useState(false);
  const [customerStroriesOpen, setCustomerStroriesOpen] = useState(false);

  const handleSolutionClick = useCallback(() => {
    setSolutionsOpen(!solutionsOpen);
    setCustomerStroriesOpen(false);
  }, [setSolutionsOpen, solutionsOpen, setCustomerStroriesOpen]);

  const handleCustomerStoriesClick = useCallback(() => {
    setCustomerStroriesOpen(!customerStroriesOpen);
    setSolutionsOpen(false);
  }, [setCustomerStroriesOpen, customerStroriesOpen, setSolutionsOpen]);

  const handleClose = useCallback(() => {
    setSolutionsOpen(false);
    setCustomerStroriesOpen(false);
  }, [setSolutionsOpen, setCustomerStroriesOpen]);

  useOnClickOutside(containerRef, handleClose);

  return (
    <Box fill ref={containerRef}>
      <SubNavBox
        fill="horizontal"
        position="absolute"
        zIndex={1}
        left="0px"
        background="grey-2"
        align="center"
        pad={{ vertical: "xlarge" }}
        border={{ color: "#E4E4E4", side: "horizontal" }}
        open={solutionsOpen}
      >
        <SubNavContainerBox
          constrained="hd"
          padded="thick"
          direction="row"
          gap="large"
          margin={{ bottom: "medium" }}
        >
          {solutions?.map((solution, index) => (
            <SubNavItemBox key={index} align="start">
              <Text weight={500} margin={{ bottom: "xxsmall" }}>
                {solution?.title}
              </Text>
              <Paragraph size="small" margin={{ bottom: "xsmall" }}>
                {solution?.cardIntro}
              </Paragraph>
              <Button
                to={`/solutions/${solution?.slug?.current}`}
                label="View"
                size="small"
                background="brand"
                color="text"
                onMouseUp={handleClose}
              />
            </SubNavItemBox>
          ))}
        </SubNavContainerBox>
      </SubNavBox>

      <SubNavBox
        fill="horizontal"
        position="absolute"
        zIndex={1}
        left="0px"
        background="grey-2"
        align="center"
        pad={{ vertical: "xlarge" }}
        border={{ color: "#E4E4E4", side: "horizontal" }}
        open={customerStroriesOpen}
      >
        <SubNavContainerBox
          constrained="hd"
          padded="thick"
          margin={{ bottom: "small" }}
        >
          <StoriesGrid>
            {customerStrories?.map((story, index) => (
              <SubNavItemBox key={index} align="start">
                <Text weight={500} margin={{ bottom: "xxsmall" }}>
                  <MenuLink
                    to={`/stories/${story?.slug?.current}`}
                    onMouseUp={handleClose}
                  >
                    {story?.title}
                  </MenuLink>
                </Text>
                <Paragraph size="small" margin={{ bottom: "xsmall" }}>
                  <MenuLink
                    to={`/stories/${story?.slug?.current}`}
                    onMouseUp={handleClose}
                  >
                    {story?.intro}
                  </MenuLink>
                </Paragraph>
              </SubNavItemBox>
            ))}
          </StoriesGrid>
        </SubNavContainerBox>
      </SubNavBox>

      <Box
        justify="center"
        align="center"
        fill
        pad={{ horizontal: "xlarge" }}
        zIndex={2}
        background="#FFFFFF"
      >
        <Box
          align="center"
          fill
          direction="row"
          gap="medium"
          width={{ max: "1024px" }}
        >
          <Box
            onClick={handleSolutionClick}
            focusIndicator={false}
            direction="row"
            align="center"
            position="relative"
          >
            <Text size="large" margin={{ right: "xxsmall" }}>
              Solutions
            </Text>
            <Svg width={25}>{DownArrowIcon}</Svg>

            <SubNavArrow open={solutionsOpen} />
          </Box>

          <Box
            onClick={handleCustomerStoriesClick}
            focusIndicator={false}
            direction="row"
            align="center"
            position="relative"
          >
            <Text size="large" margin={{ right: "xxsmall" }}>
              Customer Stories
            </Text>
            <Svg width={25}>{DownArrowIcon}</Svg>

            <SubNavArrow open={customerStroriesOpen} />
          </Box>
          <Box onClick={handleClose}>
            <Text size="large">
              <MenuLink to="/features">Features</MenuLink>
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DesktopNav;
