import React, { ComponentProps, lazy, Suspense } from "react";
import Spinner from "react-tiny-spin";
import * as s from "./style.module.css";

const VideoPlayer = lazy(() => import("@components/VideoPlayer"));
const fallback = (
  <div className={s.root}>
    <Spinner
      config={{
        width: 1,
        length: 4,
        radius: 4,
        color: "rgb(255,255,255)",
      }}
    />
  </div>
);

const LazyVideoPlayer = (props: ComponentProps<typeof VideoPlayer>) => {
  return (
    <Suspense fallback={fallback}>
      <VideoPlayer {...props} />
    </Suspense>
  );
};

export default LazyVideoPlayer;
