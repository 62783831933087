import { useContext } from "react";
import { css } from "styled-components";
import { ThemeType, ThemeContext } from "grommet";
import { ColorType } from "grommet/utils";
import { darken, lighten } from "polished";

import { TGrommetColor, ExtendedThemeType } from "./Types";

export const getEdgeBreakpoint = ({ theme }: { theme: ThemeType }) => {
  const alias = theme?.global?.edgeSize?.responsiveBreakpoint;

  return alias ? theme?.global?.breakpoints?.[alias]?.value : 350;
};

export const getBoxBreakpoint = ({ theme }: { theme: ThemeType }) => {
  const alias = theme?.box?.responsiveBreakpoint;

  return alias ? theme?.global?.breakpoints?.[alias]?.value : 350;
};

export const getTextBreakpoint = ({ theme }: { theme: ExtendedThemeType }) => {
  const alias = theme?.text?.responsiveBreakpoint;

  return alias ? theme?.global?.breakpoints?.[alias]?.value : 350;
};

export const getParagraphBreakpoint = ({
  theme,
}: {
  theme: ExtendedThemeType;
}) => {
  const alias = theme?.paragraph?.responsiveBreakpoint;

  return alias ? theme?.global?.breakpoints?.[alias]?.value : 350;
};

export const getMobileBreakpoint = ({ theme }: { theme: ThemeType }) => {
  return theme?.global?.breakpoints?.small?.value;
};

export const getDesktopBreakpoint = ({ theme }: { theme: ThemeType }) => {
  return theme?.global?.breakpoints?.medium?.value;
};

export const DesktopConstrainedContainer = css`
  max-width: 930px;

  @media (max-width: ${getMobileBreakpoint}px) {
    max-width: auto;

    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const HdConstrainedContainer = css`
  ${DesktopConstrainedContainer}

  max-width: 1280px;

  @media (max-width: ${getDesktopBreakpoint}px) {
    max-width: 930px;
  }
`;

export const ThinPaddedContainer = css`
  padding-left: 30px;
  padding-right: 30px;

  @media (max-width: ${getMobileBreakpoint}px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const ThickPaddedContainer = css`
  padding-left: 100px;
  padding-right: 100px;

  @media (max-width: ${getDesktopBreakpoint}px) {
    padding-left: 70px;
    padding-right: 70px;
  }

  @media (max-width: ${getMobileBreakpoint}px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const SecondaryFont = css`
  font-family: ${({ theme }) => theme?.global?.font?.secondary};
`;

export const getTint = (theme: ExtendedThemeType, dark?: boolean): number =>
  theme?.global?.[dark ? "tint-dark" : "tint-light"] ?? 0.075;

export const getGlobalColor = (
  theme: ExtendedThemeType,
  alias?: TGrommetColor
): string | undefined => {
  if (!alias) return undefined;

  if (alias.startsWith("#")) return alias;

  const color: ColorType = theme?.global?.colors?.[alias];

  if (typeof color === "string") return color;
  else return color?.[theme?.dark ? "dark" : "light"] ?? "green";
};

export const useGlobalColor = (
  alias?: TGrommetColor,
  tint?: "dark" | "light"
): string | undefined => {
  const theme: ExtendedThemeType = useContext(ThemeContext);

  const baseColor = getGlobalColor(theme, alias);

  if (baseColor && tint === "dark")
    return darken(getTint(theme, true), baseColor);
  if (baseColor && tint === "light")
    return lighten(getTint(theme, true), baseColor);

  return getGlobalColor(theme, alias);
};

export const pxToInt = (pxVal: string): number =>
  parseInt(pxVal?.substring(0, pxVal.length - 2));
