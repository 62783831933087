import React, { ComponentProps } from "react";
import styled from "styled-components";

import Box from "@components/Box";

type TStyleProps = {
  open?: boolean;
};

const BottomArrow = styled(Box)<TStyleProps>`
  transform: translateX(-50%) rotate(45deg);

  transition: top ease-in-out 200ms;
  top: ${({ open }) => (open ? "50%" : "125%")};
`;

type TProps = TStyleProps & ComponentProps<typeof Box>;

const SubNavArrow = ({ open, ...rest }: TProps) => {
  return (
    <Box
      position="absolute"
      left="calc(50% - 25px)"
      bottom="calc(0px - 31px)"
      width="42px"
      height="27px"
      overflow="hidden"
      {...rest}
    >
      <BottomArrow
        background="grey-2"
        width="30px"
        height="30px"
        position="absolute"
        left="50%"
        border={{ color: "#E4E4E4" }}
        open={open}
      />
    </Box>
  );
};

export default SubNavArrow;
