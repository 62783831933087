import React, { ReactNode } from "react"

type TProps = {
  w?: number
  h?: number
  children: ReactNode
}

const ProportionalBox = ({ w = 16, h = 9, children }: TProps): JSX.Element => {
  const aspectRatio = (h / Math.max(w, 1)) * 100

  return (
    <div
      style={{
        width: "100%",
        height: "0px",
        position: "relative",
        paddingTop: `${aspectRatio}%`,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "0",
          width: "100%",
          height: "100%",
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default ProportionalBox
