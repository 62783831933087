import React, { ComponentProps } from "react";
import BaseSanityImage from "gatsby-plugin-sanity-image";
import styled from "styled-components";
import { Box } from "grommet";

import Text from "@components/Text";
import ProportionalBox from "@components/ProportionalBox";

type TStyledSanityImageProps = {
  objectfit?: string;
  imgwidth?: string;
  imgheight?: string;
};

const StyledSanityImage = styled(BaseSanityImage)<TStyledSanityImageProps>`
  object-fit: ${({ objectfit }) => objectfit};
  width: ${({ imgwidth }) => imgwidth};
  height: ${({ imgheight }) => imgheight};
`;

type TProps = TStyledSanityImageProps &
  Partial<ComponentProps<typeof BaseSanityImage>> & {
    alt?: string | null;
    caption?: string;
    noProportionalPlaceholder?: boolean;
  };

const SanityImage = ({
  asset,
  alt,
  imgwidth = "100%",
  imgheight = "100%",
  caption,
  objectfit = "cover",
  noProportionalPlaceholder = false,
  ...rest
}: TProps) => {
  if (!asset) return null;

  const Image = (
    <StyledSanityImage
      asset={asset}
      {...rest}
      alt={alt}
      objectfit={objectfit}
      imgwidth={imgwidth}
      imgheight={imgheight}
    />
  );

  return (
    <Box>
      {asset?.width && asset?.height && !noProportionalPlaceholder ? (
        <ProportionalBox w={asset?.width} h={asset?.height}>
          {Image}
        </ProportionalBox>
      ) : (
        Image
      )}

      {caption && (
        <Text size="xsmall" margin={{ top: "xxsmall" }}>
          {caption}
        </Text>
      )}
    </Box>
  );
};

export default SanityImage;
