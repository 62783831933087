import { useState, useCallback, useEffect, useMemo, useRef } from 'react';

export type UseStateToggleType = [boolean, () => void, () => void, boolean?];

const useStateToggle = (initialVal = false, delay?: number): UseStateToggleType => {
  const [open, setOpen] = useState(initialVal);
  const [mount, setMount] = useState(initialVal);

  const handleOpen = useCallback(() => {
    setMount(true);
    setOpen(true);
  }, [setOpen, setMount]);
  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  const destroyTimer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (!open && mount) {
      if (delay && delay > 0) {
        if (destroyTimer.current) clearTimeout(destroyTimer.current);

        destroyTimer.current = setTimeout(() => {
          setMount(false);
        }, delay);
      }
    }

    if (open && !mount) {
      setMount(true);
    }

    return () => {
      if (destroyTimer.current) clearTimeout(destroyTimer.current);
    };
  }, [delay, open, setMount]);

  return useMemo(
    () => [open, handleOpen, handleClose, mount],
    [open, handleOpen, handleClose, mount],
  );
};

export default useStateToggle;
