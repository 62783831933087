import React from "react";
import styled from "styled-components";
import { Grid, Text } from "grommet";
import { Link } from "gatsby";

import Box from "@components/Box";
import Button from "@components/Button";
import SanityImage from "@components/SanityImage";
import useStateToggle from "@hooks/useStateToggle";
import { DesktopConstrainedContainer } from "@utils/Grommet";

const LogoLink = styled(Link)`
  height: 100%;
`;

const Burger = styled(Box)`
  &::after {
    content: "";
    width: 23px;
    height: 2px;
    background: #4a4a4a;

    position: absolute;
    top: 50%;
  }
`;

const Close = styled(Box)`
  &::after {
    content: "";
    width: 30px;
    height: 2px;
    background: #4a4a4a;

    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::before {
    content: "";
    width: 30px;
    height: 2px;
    background: #4a4a4a;

    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

const Menu = styled(Box)<{ open?: boolean }>`
  ${DesktopConstrainedContainer}

  max-width: unset;
  padding-top: 20px;
  padding-bottom: 25px;

  transform: translateX(${({ open }) => (open ? "-100%" : "0%")});
  transition: transform ease-in-out 300ms;
`;

type TProps = {
  site?: Queries.HeaderFragment | null;
  solutions?: ReadonlyArray<Queries.SolutionsNavFragment> | null;
  customerStrories?: ReadonlyArray<Queries.CustomerStoriesNavFragment> | null;
};

const MobileNav = ({ site, solutions, customerStrories }: TProps) => {
  const [isOpen, setOpen, setClose] = useStateToggle(false);

  const logoImg = site?.logo?.image;
  const imgwidth =
    ((logoImg?.asset?.width ?? 1) / (logoImg?.asset?.height ?? 1)) * 29;

  return (
    <Box>
      <Burger
        position="relative"
        border={{ side: "horizontal", color: "#4a4a4a", size: "2px" }}
        width="23px"
        height="23px"
        margin={{ right: "15px" }}
        onClick={setOpen}
        focusIndicator={false}
      />

      <Menu
        position="fixed"
        zIndex={2}
        top="0px"
        left="100%"
        width="100vw"
        height="100vh"
        background="#FFFFFF"
        open={isOpen}
      >
        <Box direction="row" justify="between" pad={{ bottom: "large" }}>
          <Box onClick={setClose}>
            <LogoLink to="/">
              <SanityImage
                {...site?.logo?.image}
                alt={site?.logo?.alt}
                objectfit="contain"
                imgwidth={`${imgwidth}px`}
              />
            </LogoLink>
          </Box>
          <Close
            position="relative"
            width="23px"
            height="23px"
            onClick={setClose}
          />
        </Box>

        <Box flex style={{ minHeight: "0px" }}>
          <Box flex overflow="auto">
            <Box
              border={{ side: "bottom", color: "grey-5", size: "2px" }}
              pad={{ bottom: "small" }}
              margin={{ bottom: "small" }}
            >
              <Text weight="bold" size="14px" margin={{ bottom: "xsmall" }}>
                Learning Solutions
              </Text>
              <Grid columns={["flex", "flex"]}>
                {solutions?.map((solution, index) => (
                  <Box key={index} pad={{ bottom: "xsmall" }}>
                    <Link
                      to={`/solutions/${solution?.slug?.current}`}
                      onClick={setClose}
                    >
                      <Text>{solution?.title}</Text>
                    </Link>
                  </Box>
                ))}
              </Grid>
            </Box>

            <Box
              border={{ side: "bottom", color: "grey-5", size: "2px" }}
              pad={{ bottom: "small" }}
              margin={{ bottom: "small" }}
            >
              <Text weight="bold" size="14px" margin={{ bottom: "xsmall" }}>
                Customer Stories
              </Text>
              <Box>
                {customerStrories?.map((story, index) => (
                  <Box key={index} pad={{ bottom: "xsmall" }}>
                    <Link
                      to={`/stories/${story?.slug?.current}`}
                      onClick={setClose}
                    >
                      <Text>{story?.title}</Text>
                    </Link>
                  </Box>
                ))}
              </Box>
            </Box>

            <Box>
              <Link to="/features" onClick={setClose}>
                <Text>Product Features</Text>
              </Link>
            </Box>
          </Box>

          <Box pad={{ top: "xlarge" }}>
            <Button
              to="/contact"
              label="Get in touch"
              background="brand"
              color="text"
              size="large"
              onClick={setClose}
            />
          </Box>
        </Box>
      </Menu>
    </Box>
  );
};

export default MobileNav;
