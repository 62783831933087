import React from "react";
import styled from "styled-components";
import { BoxExtendedProps } from "grommet";
import { graphql, Link as BaseLink } from "gatsby";

import { getMobileBreakpoint } from "@utils/Grommet";
import SanityImage from "@components/SanityImage";
import DesktopNav from "./components/DesktopNav";
import MobileNav from "./components/MobileNav";
import Button from "@components/Button";
import Box from "@components/Box";

const Container = styled(Box)<BoxExtendedProps>`
  background: #ffffff;
  position: sticky;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid #e4e4e4;
`;

const Content = styled(Box)`
  height: 90px;

  @media (max-width: ${getMobileBreakpoint}px) {
    height: 72px;
  }
`;

const Link = styled(BaseLink)`
  height: 100%;
`;

const ResponsiveBox = styled(Box)<{ mobile?: boolean }>`
  display: ${({ mobile }) => (mobile ? "none" : "flex")};

  @media (max-width: ${getMobileBreakpoint}px) {
    display: ${({ mobile }) => (mobile ? "flex" : "none")};
  }
`;

type TProps = {
  site?: Queries.HeaderFragment | null;
  solutions?: ReadonlyArray<Queries.SolutionsNavFragment> | null;
  customerStrories?: ReadonlyArray<Queries.CustomerStoriesNavFragment> | null;
};

const Header = ({ site, solutions, customerStrories }: TProps) => {
  const logoImg = site?.logo?.image;
  const imgwidth =
    ((logoImg?.asset?.width ?? 1) / (logoImg?.asset?.height ?? 1)) * 37;

  return (
    <Container as="header" align="center">
      <Content direction="row" justify="between" align="center">
        <Box
          justify="center"
          zIndex={11}
          background="#FFFFFF"
          fill="vertical"
          align="center"
          pad={{ left: "small" }}
        >
          <Box>
            <Link to="/">
              <SanityImage
                {...site?.logo?.image}
                alt={site?.logo?.alt}
                objectfit="contain"
                imgwidth={`${imgwidth}px`}
                noProportionalPlaceholder
              />
            </Link>
          </Box>
        </Box>

        <ResponsiveBox flex fill="vertical" zIndex={10}>
          <DesktopNav
            solutions={solutions}
            customerStrories={customerStrories}
          />
        </ResponsiveBox>

        <ResponsiveBox
          zIndex={12}
          background="#FFFFFF"
          fill="vertical"
          justify="center"
          pad={{ right: "small" }}
        >
          <Button
            to="/contact"
            label="Get in touch"
            background="brand"
            color="text"
            responsive={false}
          />
        </ResponsiveBox>

        <ResponsiveBox mobile zIndex={13}>
          <MobileNav
            site={site}
            solutions={solutions}
            customerStrories={customerStrories}
          />
        </ResponsiveBox>
      </Content>
    </Container>
  );
};

export default Header;

export const query = graphql`
  fragment Header on SanitySite {
    logo {
      image {
        ...ImageWithPreview

        asset {
          width
          height
        }
      }
      alt
    }
  }

  fragment SolutionsNav on SanitySolution {
    _id
    id
    title
    cardIntro

    slug {
      current
    }
  }

  fragment CustomerStoriesNav on SanityCustomerStory {
    _id
    id
    title
    intro

    slug {
      current
    }
  }
`;
