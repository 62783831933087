import React, { ReactNode } from "react";
import styled from "styled-components";
import { Box } from "grommet";
import { useStaticQuery, graphql } from "gatsby";

import Header from "@components/Header";
import Footer from "@components/Footer";

const Container = styled(Box)`
  min-height: 100vh;
`;

type TProps = {
  children: ReactNode;
};

const Layout = ({ children }: TProps): JSX.Element => {
  const {
    sanitySite: site,
    allSanitySolution,
    allSanityCustomerStory,
  } = useStaticQuery<Queries.SiteLayoutQuery>(graphql`
    query SiteLayout {
      sanitySite {
        _id
        id

        ...Header
        ...Footer
      }

      allSanitySolution(sort: { fields: order }) {
        edges {
          node {
            ...SolutionsNav
          }
        }
      }

      allSanityCustomerStory {
        edges {
          node {
            ...CustomerStoriesNav
          }
        }
      }
    }
  `);

  const solutions = allSanitySolution?.edges?.map((edge) => edge?.node);
  const customerStrories = allSanityCustomerStory?.edges?.map(
    (edge) => edge?.node
  );

  return (
    <Container fill background="background" justify="between">
      <Header
        site={site}
        solutions={solutions}
        customerStrories={customerStrories}
      />

      <Box flex>
        <Box flex as="main">
          {children}
        </Box>

        <Footer site={site} />
      </Box>
    </Container>
  );
};

export default Layout;
