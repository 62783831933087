import React, { forwardRef, LegacyRef } from "react";
import { Box, BoxExtendedProps } from "grommet";

import ResponsiveContainerBox, {
  TContainerProps,
} from "@components/ResponsiveContainerBox";

type TProps = BoxExtendedProps & TContainerProps;

const Section = forwardRef(
  (
    { children, constrained, padded, ...rest }: TProps,
    ref: LegacyRef<HTMLDivElement>
  ): JSX.Element => {
    return (
      <Box ref={ref} as="section" align="center" {...rest}>
        {constrained ? (
          <ResponsiveContainerBox
            fill="horizontal"
            constrained={constrained}
            padded={padded}
          >
            {children}
          </ResponsiveContainerBox>
        ) : (
          children
        )}
      </Box>
    );
  }
);

export default Section;
