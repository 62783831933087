import React from "react";
import styled from "styled-components";
import { Box, BoxExtendedProps } from "grommet";

import {
  DesktopConstrainedContainer,
  HdConstrainedContainer,
  ThinPaddedContainer,
  ThickPaddedContainer,
} from "@utils/Grommet";

export type TContainerProps = {
  constrained?: "desktop" | "hd" | "horPadded";
  padded?: "thin" | "thick";
};

const getWidthStyles = ({ constrained }: TContainerProps) => {
  switch (constrained) {
    case "hd":
      return HdConstrainedContainer;
    case "desktop":
      return DesktopConstrainedContainer;
    case "horPadded":
      return ThinPaddedContainer;
    default:
      return {};
  }
};

const getPaddingStyles = ({ padded }: TContainerProps) => {
  switch (padded) {
    case "thin":
      return ThinPaddedContainer;
    case "thick":
      return ThickPaddedContainer;
    default:
      return {};
  }
};

const ContainerBox = styled(Box)<TContainerProps>`
  ${getWidthStyles}
  ${getPaddingStyles}
`;

type TProps = BoxExtendedProps & TContainerProps;

const ResponsiveContainerBox = ({
  children,
  constrained,
  padded,
  ...rest
}: TProps) => {
  return (
    <ContainerBox
      fill="horizontal"
      constrained={constrained}
      padded={padded}
      {...rest}
    >
      {children}
    </ContainerBox>
  );
};

export default ResponsiveContainerBox;
