import { createGlobalStyle } from "styled-components";
import { ThemeType } from "grommet";

// prettier-ignore
export default createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    background-color: white;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-family: ${({ theme }: { theme: ThemeType }) =>
      theme?.global?.font?.family};
    font-weight: ${({ theme }: { theme: ThemeType }) =>
      theme?.global?.font?.weight};
    font-size: ${({ theme }: { theme: ThemeType }) =>
      theme?.global?.font?.size};
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  strong {
    /* font-weight: 500; */
  }

  em {
    font-style:italic;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  video {
    max-width: 100%;
  }

  button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: inherit;
  }

  input, textarea {
    display: block;
    width: 100%;
    border: none;
    background: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
    outline: none;
    color: inherit;
    font: inherit;
  }

  input[type=radio], input[type=checkbox] {
    display: inline-block;
    width: auto;
  }

  input[type=checkbox] {
    -webkit-appearance: checkbox;
    -moz-appearance: checkbox;
  }

  input[type=radio] {
    -webkit-appearance: radio;
    -moz-appearance: radio;
  }

  iframe[name='google_conversion_frame'] {
    position: absolute;
  }

  #gatsby-focus-wrapper {
    height: 100%;
    width: 100%;
  }

`
