import * as React from "react";
import type { GatsbyBrowser } from "gatsby";

import GlobalStyle from "./src/theming/styles";
import Providers from "./src/components/Providers";
import Layout from "./src/components/Layout";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => {
  return <Providers>{element}</Providers>;
};

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props,
}) => {
  return (
    <Layout {...props}>
      <GlobalStyle />
      {element}
    </Layout>
  );
};
