import React from "react";
import styled from "styled-components";
import { Text as BaseText, TextExtendedProps } from "grommet";

import { getTextBreakpoint } from "@utils/Grommet";

// prettier-ignore
const CustomText = styled(BaseText)<TextExtendedProps>`
  font-size: ${({theme, size})=> theme?.text?.[size as any]?.size};
  line-height: ${({theme, size})=> theme?.text?.[size as any]?.height};

  @media (max-width: ${getTextBreakpoint}px) {
    font-size: ${({theme, size})=> theme?.text?.[size as any]?.mobileSize ?? theme?.text?.[size as any]?.size};
    line-height: ${({theme, size})=> theme?.text?.[size as any]?.mobileHeight ?? theme?.text?.[size as any]?.height};
  }
`

const Text = ({
  children,
  size = "medium",
  as,
  ...rest
}: TextExtendedProps) => {
  return (
    <CustomText forwardedAs={as} size={size} {...rest}>
      {children}
    </CustomText>
  );
};

export default Text;
