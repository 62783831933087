import React from "react";
import styled from "styled-components";
import { Paragraph as BaseParagraph, ParagraphExtendedProps } from "grommet";

import { getParagraphBreakpoint } from "@utils/Grommet";

// prettier-ignore
const CustomParagraph = styled(BaseParagraph)<ParagraphExtendedProps>`
  font-size: ${({theme, size})=> theme?.paragraph?.[size as any]?.size};
  line-height: ${({theme, size})=> theme?.paragraph?.[size as any]?.height};

  @media (max-width: ${getParagraphBreakpoint}px) {
    font-size: ${({theme, size})=> theme?.paragraph?.[size as any]?.mobileSize ?? theme?.paragraph?.[size as any]?.size};
    line-height: ${({theme, size})=> theme?.paragraph?.[size as any]?.mobileHeight ?? theme?.paragraph?.[size as any]?.height};
  }
`

const Paragraph = ({
  children,
  size = "medium",
  ...rest
}: Omit<ParagraphExtendedProps, "ref">) => {
  return (
    <CustomParagraph size={size} {...rest}>
      {children}
    </CustomParagraph>
  );
};

export default Paragraph;
