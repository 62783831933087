import React, { forwardRef, cloneElement, SVGProps } from "react";
import styled from "styled-components";

import { pxToInt } from "@utils/Grommet";

const StyledSvg = styled.svg<{ fill?: string }>`
  & path,
  & g {
    fill: ${({ fill }) => fill && fill};
  }
`;

type TProps = Omit<SVGProps<SVGSVGElement>, "css"> & {
  children: JSX.Element;
};

const Svg = forwardRef(
  ({ children, width, fill, ...props }: TProps, ref): JSX.Element => {
    if (children.type === "svg") {
      const { viewBox, ...rest } = children?.props;
      const viewboxArr = String(viewBox).split(" ");
      const masterWidth = width ?? children?.props?.width ?? 0;
      const vb = {
        width: parseInt(viewboxArr[2]),
        height: parseInt(viewboxArr[3]),
      };
      const intWidth =
        typeof masterWidth === "string" ? pxToInt(masterWidth) : masterWidth;

      return (
        <StyledSvg
          ref={ref}
          {...rest}
          viewBox={viewBox}
          width={width}
          height={`${intWidth * (vb.height / vb.width)}px`}
          fill={fill}
          {...props}
        >
          {cloneElement(children?.props?.children)}
        </StyledSvg>
      );
    }

    return children;
  }
);

export default Svg;
