import React, { useCallback } from "react";
import { Anchor, Box, Grid, Paragraph, Text } from "grommet";
import styled from "styled-components";
import { graphql, Link, navigate } from "gatsby";
import { PortableText } from "@portabletext/react";

import Button from "@components/Button";
import Section from "@components/Section";
import SanityImage from "@components/SanityImage";
import Svg from "@components/Svg";
import { defaultComponents } from "@utils/PortableText";
import { NewWindowIcon } from "@icons/Common";
import { getMobileBreakpoint } from "@utils/Grommet";

const AwardContainer = styled(Box)`
  padding: 34px 0;

  @media (max-width: ${getMobileBreakpoint}px) {
    align-items: center;

    padding: 27px;
  }
`;

const Awards = styled(Box)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${getMobileBreakpoint}px) {
    flex-direction: column;

    & > *:not(:last-child) {
      margin-bottom: 22px;
    }
  }
`;

const Featured = styled(Grid)`
  grid-auto-flow: column;
  grid-column-gap: 60px;
  align-items: center;
  justify-items: end;
  width: 100%;

  & > *:first-child {
    justify-self: start;
  }

  @media (max-width: ${getMobileBreakpoint}px) {
    grid-auto-flow: unset;
    grid-template-columns: 1fr 1fr;
    justify-items: center;

    max-width: 342px;

    & > *:first-child {
      justify-self: center;
    }

    & > *:nth-child(3n + 1) {
      grid-column-start: 1;
      grid-column-end: span 2;
    }

    & > *:not(:last-child) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  & span {
    white-space: nowrap;
  }
`;

const Contact = styled(Box)`
  padding: 30px 0;

  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${getMobileBreakpoint}px) {
    flex-direction: column;
    align-items: center;

    text-align: center;
  }
`;

const ContactBox = styled(Box)`
  align-items: flex-start;

  @media (max-width: ${getMobileBreakpoint}px) {
    align-items: center;
  }
`;

const ContactCell = styled(ContactBox)`
  justify-content: space-between;

  @media (max-width: ${getMobileBreakpoint}px) {
    max-width: 300px;
  }
`;

type TProps = {
  site?: Queries.FooterFragment | null;
};

const Footer = ({ site }: TProps) => {
  const awards = site?.awardsAndAccreditations;
  const featured = site?.featured;

  const handleContactClick = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  return (
    <Box as="footer">
      <Section constrained="hd">
        <AwardContainer>
          <Text margin={{ bottom: "22px" }}>Awards & Accreditations</Text>
          <Awards>
            {awards?.map((logo, index) => {
              const image = (
                <SanityImage
                  {...logo?.image}
                  alt={logo?.alt}
                  objectfit="contain"
                  imgwidth={`${logo?.maxWidth}px` || "100%"}
                />
              );

              return (
                <Box key={index} align="center" justify="center">
                  {logo?.link ? (
                    <Anchor href={logo?.link} target="_blank">
                      {image}
                    </Anchor>
                  ) : (
                    image
                  )}
                </Box>
              );
            })}
          </Awards>
        </AwardContainer>
      </Section>

      <Section constrained="hd" background="grey-10">
        <Box border={{ side: "bottom", color: "grey-4" }} align="center">
          <Featured pad={{ vertical: "30px" }}>
            <Box>
              <Text>As featured in</Text>
            </Box>

            {featured?.map((logo, index) => {
              const image = (
                <SanityImage
                  {...logo?.image}
                  alt={logo?.alt}
                  objectfit="contain"
                  imgwidth={`${logo?.maxWidth}px` || "100%"}
                />
              );

              return (
                <Box key={index} align="center" justify="center">
                  {logo?.link ? (
                    <Anchor href={logo?.link} target="_blank">
                      {image}
                    </Anchor>
                  ) : (
                    image
                  )}
                </Box>
              );
            })}
          </Featured>
        </Box>

        <Contact gap="large">
          <ContactCell flex width={{ max: "470px" }}>
            <ContactBox margin={{ bottom: "medium" }}>
              <Text size="24px" margin={{ bottom: "3px" }}>
                Get in touch
              </Text>
              <Paragraph size="small" margin={{ bottom: "12px" }}>
                If you'd like to learn more about how Learning with Experts for
                Business can work with you, please get it touch.
              </Paragraph>
              <Button
                background="brand"
                label={
                  <Text size="16px" weight={500}>
                    Contact us
                  </Text>
                }
                onClick={handleContactClick}
              />
            </ContactBox>

            <Box direction="row" gap="10px" align="center">
              <Text size="small">Follow us</Text>
              <Anchor href={site?.linkedInLink ?? ""} target="_blank">
                <SanityImage
                  {...site?.linkedInLogo?.image}
                  alt={site?.linkedInLogo?.alt}
                />
              </Anchor>
            </Box>
          </ContactCell>

          <ContactCell>
            <Text size="18px" weight={500} margin={{ vertical: "3px" }}>
              Office
            </Text>
            <Box margin={{ bottom: "23px" }}>
              {site?._rawAddress && (
                <PortableText
                  value={site?._rawAddress as any}
                  components={defaultComponents({
                    tSize: "small",
                    tMargin: "unset",
                  })}
                />
              )}
            </Box>

            <Text size="18px" weight={500} margin={{ bottom: "5px" }}>
              For Individuals
            </Text>
            <Box direction="row" gap="xxsmall" align="center">
              <Anchor
                href="https://www.learningwithexperts.com/"
                target="_blank"
              >
                <Text size="small">learningwithexperts.com</Text>
              </Anchor>
              <Svg fill="#EFEFEF" width={25}>
                {NewWindowIcon}
              </Svg>
            </Box>
          </ContactCell>
        </Contact>
      </Section>

      <Section>
        <Box
          direction="row"
          justify="center"
          gap="small"
          wrap
          pad={{ top: "16px", bottom: "14px" }}
        >
          <Text size="small" margin={{ bottom: "2px" }}>
            © {new Date().getFullYear()} Learning with Experts
          </Text>
          <Link to="/terms">
            <Text size="small">Terms & conditions</Text>
          </Link>
          <Link to="/privacy">
            <Text size="small">Privacy policy</Text>
          </Link>
        </Box>
      </Section>
    </Box>
  );
};

export default Footer;

export const query = graphql`
  fragment Footer on SanitySite {
    awardsAndAccreditations {
      image {
        ...ImageWithPreview
      }
      alt
      link
      maxWidth
    }

    featured {
      image {
        ...ImageWithPreview
      }
      alt
      link
      maxWidth
    }

    linkedInLink

    linkedInLogo {
      image {
        ...ImageWithPreview
      }
      alt
    }

    _rawAddress
  }
`;
