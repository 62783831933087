import React, { ComponentProps } from "react";
import {
  PortableTextComponentProps,
  PortableTextMarkComponentProps,
  PortableTextReactComponents,
  ReactPortableTextList,
  ReactPortableTextListItem,
} from "@portabletext/react";
import type { PortableTextBlock, PortableTextLink } from "@portabletext/types";
import { Anchor, Box, Heading } from "grommet";
import { deepMerge } from "grommet/utils";
import { builder } from "gatsby-plugin-sanity-image";

import Paragraph from "@components/Paragraph";
import Text from "@components/Text";
import Svg from "@components/Svg";
import { TickIcon } from "@icons/Common";
import SanityImage from "@components/SanityImage";
import VideoPlayer from "@components/LazyVideoPlayer";

type TRichImage = {
  alt: string;
  image?: ComponentProps<typeof SanityImage>;
  caption?: string;
  link?: string;
  credit?: string;
  creditLink?: string;
  maxWidth?: number;
};

type TMuxVideo = {
  video: {
    asset?: {
      playbackId?: string;
    };
  };
  caption?: string;
  customPoster?: ComponentProps<typeof SanityImage>;
};

type THLevel = 1 | 2 | 3 | 4 | 5;
type TSize = "small" | "medium" | "large" | "xlarge";
type TEdgeSize = "unset" | "xxsmall" | "xsmall" | TSize;

type TDefaultComponentsProps = {
  hLevel?: THLevel;
  hSize?: TSize;
  tSize?: TSize;
  hMargin?: TEdgeSize;
  tMargin?: TEdgeSize;
};

export const defaultComponents = ({
  hLevel = 1,
  hSize = "medium",
  tSize = "medium",
  tMargin = "xsmall",
  hMargin = "xsmall",
}: TDefaultComponentsProps = {}): Partial<PortableTextReactComponents> => {
  const subheadingLevel = (hLevel - 1) as 2 | 3 | 4 | 5 | 6;

  return {
    block: {
      heading: ({
        children,
      }: PortableTextComponentProps<PortableTextBlock>) => (
        <Heading level={hLevel} size={hSize} margin={{ bottom: hMargin }}>
          {children}
        </Heading>
      ),
      subheading: ({
        children,
      }: PortableTextComponentProps<PortableTextBlock>) => (
        <Heading
          level={subheadingLevel}
          size={hSize}
          margin={{ bottom: hMargin }}
        >
          {children}
        </Heading>
      ),
      normal: ({ children }: PortableTextComponentProps<PortableTextBlock>) => (
        <Paragraph margin={{ bottom: tMargin }} size={tSize}>
          {children}
        </Paragraph>
      ),
    },
    list: {
      bullet: ({
        children,
      }: PortableTextComponentProps<ReactPortableTextList>) => (
        <Text as="ul" margin={{ vertical: tMargin }} size={tSize}>
          {children}
        </Text>
      ),
      number: ({
        children,
      }: PortableTextComponentProps<ReactPortableTextList>) => (
        <Text as="ol" margin={{ vertical: tMargin }} size={tSize}>
          {children}
        </Text>
      ),
    },
    listItem: {
      bullet: ({
        children,
      }: PortableTextComponentProps<ReactPortableTextListItem>) => (
        <Text as="li" margin={{ bottom: tMargin }} size={tSize}>
          {children}
        </Text>
      ),
      number: ({
        children,
      }: PortableTextComponentProps<ReactPortableTextListItem>) => (
        <Text as="li" margin={{ bottom: tMargin }} size={tSize}>
          {children}
        </Text>
      ),
    },
    marks: {
      link: ({
        value,
        children,
      }: PortableTextMarkComponentProps<PortableTextLink>) => {
        const target = (value?.href || "").startsWith("http")
          ? "_blank"
          : undefined;

        return (
          <Anchor
            href={value?.href}
            target={target}
            size={tSize}
            rel={target === "_blank" ? "noindex nofollow" : undefined}
          >
            {children}
          </Anchor>
        );
      },
    },
    types: {
      richImage: ({ value }: PortableTextComponentProps<TRichImage>) => {
        return (
          <Box margin={{ bottom: tMargin }} pad={{ vertical: tMargin }}>
            <SanityImage
              {...value?.image}
              alt={value?.alt}
              imgwidth={value?.maxWidth ? `${value?.maxWidth}px` : "100%"}
              caption={value?.caption}
            />
          </Box>
        );
      },
      richVideo: ({ value }: PortableTextComponentProps<TMuxVideo>) => {
        const videoHlsURL = `https://stream.mux.com/${value?.video?.asset?.playbackId}.m3u8`;
        const videoThumb = `https://image.mux.com/${value?.video?.asset?.playbackId}/thumbnail.jpg`;
        const videoPoster = value?.customPoster
          ? builder.image(value?.customPoster).width(700).url() ?? ""
          : videoThumb;

        return (
          <Box margin={{ bottom: tMargin }} pad={{ vertical: tMargin }}>
            <VideoPlayer hlsURL={videoHlsURL} poster={videoPoster} />
            {value?.caption && (
              <Text size="xsmall" margin={{ top: "xxsmall" }}>
                {value?.caption}
              </Text>
            )}
          </Box>
        );
      },
    },
  };
};

export const tickBulletList = (
  props: TDefaultComponentsProps
): Partial<PortableTextReactComponents> => {
  return deepMerge(defaultComponents(props), {
    listItem: {
      bullet: ({
        children,
      }: PortableTextComponentProps<ReactPortableTextListItem>) => (
        <Box
          direction="row"
          gap="xxsmall"
          margin={{ bottom: "xsmall" }}
          align="center"
        >
          <Svg width={20} fill="#ECC35D">
            {TickIcon}
          </Svg>
          <Box flex>
            <Text as="li">{children}</Text>
          </Box>
        </Box>
      ),
    },
  });
};
