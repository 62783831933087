import React, { AnchorHTMLAttributes, ClassAttributes } from "react";
import styled from "styled-components";
import {
  Button as BaseButton,
  ButtonExtendedProps,
  Text,
  ThemeContext,
} from "grommet";
import { Link } from "gatsby";

import ResponsiveText from "@components/Text";
import { getEdgeBreakpoint, useGlobalColor } from "@utils/Grommet";
import { TGrommetColor } from "@utils/Types";

const extendTheme = (
  mainColor?: string,
  hoverColor?: string,
  color?: string
) => ({
  global: {
    colors:
      mainColor && hoverColor
        ? {
            control: mainColor,
            hover: hoverColor,
          }
        : {},
  },
  button: color
    ? {
        default: {
          color: color,
        },
        primary: {
          color: color,
        },
        hover: {
          default: {
            color: color,
          },
          primary: {
            color: color,
          },
        },
      }
    : {},
});

const StyledButton = styled(BaseButton)<{ responsive?: boolean }>`
  @media (max-width: ${getEdgeBreakpoint}px) {
    padding: ${({ responsive }) => responsive && " 4px 8px"};
  }

  &:hover {
    text-decoration: none;
  }
`;

const getTextSize = (size?: string) => {
  switch (size) {
    case "large":
      return "large";
    default:
      return "small";
  }
};

type GrommetButtonProps = ButtonExtendedProps &
  ClassAttributes<HTMLAnchorElement> &
  AnchorHTMLAttributes<HTMLAnchorElement>;

type TProps = Omit<GrommetButtonProps, "color" | "gap"> & {
  background?: TGrommetColor;
  color?: TGrommetColor;
  to?: string;
  responsive?: boolean;
};

const Button = ({
  to,
  label,
  size,
  responsive = true,
  background,
  color,
  ...rest
}: TProps) => {
  const TextComp = responsive ? ResponsiveText : Text;

  const backgroundStr = useGlobalColor(background);
  const backgroundHoverStr = useGlobalColor(background, "light");
  const colorStr = useGlobalColor(color);

  return (
    <ThemeContext.Extend
      value={extendTheme(backgroundStr, backgroundHoverStr, colorStr)}
    >
      <StyledButton
        {...rest}
        size={size}
        forwardedAs={to ? Link : undefined}
        to={to}
        responsive={responsive}
        label={
          typeof label === "string" ? (
            <TextComp size={getTextSize(size)} weight={500}>
              {label}
            </TextComp>
          ) : (
            label
          )
        }
      />
    </ThemeContext.Extend>
  );
};

export default Button;
